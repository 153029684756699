import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import cn from 'clsx'
import PagesCard from './PagesCard'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { gintoHeaderFontFamily } from '../../shared-components/theme'
import { landingMobileBreakpoint } from '@pages/index'
import { landingSmallBreakpoint } from '../../../pages'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(18),
    },
  },
  title: {
    fontFamily: gintoHeaderFontFamily,
    textAlign: 'center',
    marginTop: theme.spacing(6),
    fontSize: '2rem',
    lineHeight: '2rem',
    padding: theme.spacing(0, 3),
    color: theme.palette.text.primary,
    fontWeight: '700',
    [theme.breakpoints.up('md')]: {
      fontSize: '3rem',
      whiteSpace: 'pre-wrap',
      lineHeight: '2.625rem',
      marginTop: 0,
    },
    '& > span': {
      color: theme.palette.secondary.main,
    },
  },
  subtitle: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    lineHeight: '1.5rem',
    padding: theme.spacing(0, 3),
    marginBottom: theme.spacing(2.5),
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      // fontSize: '2rem',
      // whiteSpace: 'pre-wrap',
      // lineHeight: '2.625rem',
      // marginTop: 0
    },
  },
  button: {
    flexGrow: 0,
    flexShrink: 1,
  },
  greenButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  secondaryButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 240,
  },
  pagesContainer: {
    alignSelf: 'center',
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(1.5),
    },
    [theme.breakpoints.down('sm')]: {
      transform: 'translateX(70px)',
    },
  },
  twoPages: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(1.5),
    },
  },
  marginTop3: {
    marginTop: theme.spacing(3),
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  h2: {
    fontSize: '24px',
    lineHeight: '21.6px',
    letterSpacing: '-0.5px',
    fontFamily: gintoHeaderFontFamily,
    fontWeight: 800,
    margin: theme.spacing(6, 'auto'),
    textAlign: 'center',
    padding: theme.spacing(0, 3),
    textTransform: 'uppercase',
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      fontSize: '20px',
      lineHeight: '18px',
    },
  },
  stats: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: theme.spacing(6),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    [theme.breakpoints.down(landingSmallBreakpoint)]: {
      flexWrap: 'wrap',
      gap: theme.spacing(4),
      width: 'auto',
    },
  },
  stat: {
    width: '100%',
    maxWidth: 265,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(landingSmallBreakpoint)]: {
      width: 'auto',
    },
  },
  statText: {
    fontSize: '12px',
    lineHeight: '14.32px',
    fontWeight: 800,
    letterSpacing: '0.15px',
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      fontSize: '10.08px',
      lineHeight: '12.03px',
      fontWeight: 700,
      letterSpacing: '0.13px',
    },
  },
  statNumber: {
    fontSize: '1.875rem',
    lineHeight: '2.25rem',
    fontWeight: 700,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      lineHeight: '1.75rem',
    },
  },
}))

const PagesTopSection = ({ pages }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  if (!pages || pages.length === 0) return null

  const stats = (
    <div className={classes.stats}>
      <div className={classes.stat}>
        <Typography className={classes.statText} variant='caption'>
          ACTIVE USERS
        </Typography>
        <Typography variant='h6' className={classes.statNumber}>
          130K+
        </Typography>
      </div>

      <div className={classes.stat}>
        <Typography className={classes.statText} variant='caption'>
          WEEKLY EVENTS
        </Typography>
        <Typography variant='h6' className={classes.statNumber}>
          3000+
        </Typography>
      </div>
      <div className={classes.stat}>
        <Typography className={classes.statText} variant='caption'>
          ORGANISERS
        </Typography>
        <Typography variant='h6' className={classes.statNumber}>
          1200+
        </Typography>
      </div>
      <div className={classes.stat}>
        <Typography className={classes.statText} variant='caption'>
          TICKETS SOLD
        </Typography>
        <Typography variant='h6' className={classes.statNumber}>
          18K+
        </Typography>
      </div>
    </div>
  )

  return (
    <div className={classes.root}>
      <Typography variant='h2' className={classes.h2}>
        {/*Join a fast-growing <span className={classes.secondary}>community</span>{' '}*/}
        {/*in the Netherlands*/}
        Join the next-gen
        <span className={classes.secondary}> social</span> network for events
      </Typography>

      {!isMobile && stats}

      <div className={classes.pagesContainer}>
        <div className={cn(classes.twoPages, classes.marginTop3)}>
          <PagesCard page={pages[0]} />
          <PagesCard page={pages[1]} />
        </div>
        <div className={classes.twoPages}>
          <PagesCard page={pages[2]} />
          <PagesCard page={pages[3]} />
        </div>
        <div className={cn(classes.twoPages, classes.marginTop3)}>
          <PagesCard page={pages[4]} />
          <PagesCard page={pages[5]} />
        </div>
        <div className={classes.twoPages}>
          <PagesCard page={pages[6]} />
          <PagesCard page={pages[7]} />
        </div>
        <div className={cn(classes.twoPages, classes.marginTop3)}>
          <PagesCard page={pages[8]} />
          <PagesCard page={pages[9]} />
        </div>
        {pages.length > 11 && (
          <div className={classes.twoPages}>
            <PagesCard page={pages[10]} />
            <PagesCard page={pages[11]} />
          </div>
        )}
        {pages.length > 13 && (
          <div className={cn(classes.twoPages, classes.marginTop3)}>
            <PagesCard page={pages[12]} />
            <PagesCard page={pages[13]} />
          </div>
        )}
        {pages.length > 15 && (
          <div className={classes.twoPages}>
            <PagesCard page={pages[14]} />
            <PagesCard page={pages[15]} />
          </div>
        )}
        {pages.length > 17 && (
          <div className={cn(classes.twoPages, classes.marginTop3)}>
            <PagesCard page={pages[16]} />
            <PagesCard page={pages[17]} />
          </div>
        )}
        {pages.length > 19 && (
          <div className={classes.twoPages}>
            <PagesCard page={pages[18]} />
            <PagesCard page={pages[19]} />
          </div>
        )}
        {pages.length > 21 && (
          <div className={cn(classes.twoPages, classes.marginTop3)}>
            <PagesCard page={pages[20]} />
            <PagesCard page={pages[21]} />
          </div>
        )}
      </div>
      {isMobile && stats}
    </div>
  )
}

export default PagesTopSection
